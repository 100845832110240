import Vue from 'vue'

const Auth = () => import(/* webpackChunkName: "auth" */ '@/components/Auth.vue')
const Toast = () => import(/* webpackChunkName: "toast" */ '@/components/Toast.vue')
const Tags = () => import(/* webpackChunkName: "tags" */ '@/components/Tags.vue')
const Map = () => import(/* webpackChunkName: "map" */ '@/components/Map.vue')
const DriverLayout = () => import(/* webpackChunkName: "driver-layout" */ '@/components/DriverLayout.vue')
const ScanBarcode = () => import(/* webpackChunkName: "scan-barcode" */ '@/components/ScanBarcode.vue')
const FeatureBanner = () => import(/* webpackChunkName: "feature-banner" */ '@/components/features/Banner.vue')
const FeatureVoucher = () => import(/* webpackChunkName: "feature-voucher" */ '@/components/features/Voucher.vue')
const FeatureTourGuide = () => import(/* webpackChunkName: "feature-tour-guide" */ '@/components/features/TourGuide.vue')
const FeatureCustomerPoint = () => import(/* webpackChunkName: "feature-customer-point" */ '@/components/features/CustomerPoint.vue')
const InformationSettingHtml = () => import(/* webpackChunkName: "information-setting-html" */ '@/components/InformationSettingHtml.vue')

Vue.component('Auth', Auth)
Vue.component('Toast', Toast)
Vue.component('Tags', Tags)
Vue.component('Map', Map)
Vue.component('DriverLayout', DriverLayout)
Vue.component('ScanBarcode', ScanBarcode)
Vue.component('FeatureBanner', FeatureBanner)
Vue.component('FeatureVoucher', FeatureVoucher)
Vue.component('FeatureTourGuide', FeatureTourGuide)
Vue.component('FeatureCustomerPoint', FeatureCustomerPoint)
Vue.component('InformationSettingHtml', InformationSettingHtml)
