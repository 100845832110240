import request from '../_api/request'

const state = {
  productData: [],
  pricing: {
    updated: {
      price: 0,
      date: (new Date()).toISOString()
    },
    data: []
  },
  pricing_option: null,
  categoryData: []
}

const mutations = {
  SET_PRODUCT (state, v) {
  },
  CATEGORY_SET (state, v) {
    state.categoryData = v || []
  },
  SET_PRICING (state, v) {
    state.pricing.updated.price_buyback = v.updated.price_buyback || 0
    state.pricing.updated.price = v.updated.price || 0
    state.pricing.updated.date = v.updated.date || (new Date()).toISOString()
    state.pricing.data = v.data || []
  },
  SET_PRICING_OPTION (state, v) {
    state.pricing_option = Object.assign({}, v)
  }
}

const actions = {
  async PRODUCT_GET (context, query) {
    return request.get('product' + (query || '')).then(res => res).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PROCESS (context, params) {
    return request.post('product', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_CATEGORY_GET (context) {
    return request.get('product/category').then((res) => {
      if (res && res.status && res.data.data) {
        context.commit('CATEGORY_SET', res.data.data || [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_CATEGORY_PROCESS (context, params) {
    return request.post('product/category', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_BRAND_PROCESS (context, params) {
    return request.post('product/brand', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PRICING_GET (context, query) {
    return request.get('product/pricing' + (query || '')).then((res) => {
      if (res) {
        if (res.status) {
          const d = res.data.data.length ? res.data.data[0] : {}
          context.commit('SET_PRICING', {
            updated: {
              price_buyback: d.price_buyback,
              price: d.price,
              date: d.created
            },
            data: res.data.data || []
          })
        }
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PRICING_PROCESS (context, params) {
    return request.post('product/pricing', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PRICING_SET_GET (context) {
    return request.get('product/pricing-option').then((res) => {
      if (res) {
        if (res.status) {
          context.commit('SET_PRICING_OPTION', res.data.data || {})
        }
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRODUCT_PRICING_SET_PROCESS (context, params) {
    return request.post('product/pricing-option', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
