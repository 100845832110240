<template>
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)" class="py-4">
    <v-row>
      <v-col
        v-for="[icon, text, slug] in menus.filter(r => $role(r[3], $store.state.user.role))"
        :key="icon + slug"
        cols="12"
        class="col-sm-3 px-4"
      >
        <v-card v-if="text === 'Information'" class="d-flex align-center justify-center pa-4 primary white--text" @click.prevent="OpenInformation()">
          <v-icon class="mr-2" dark>
            {{ icon }}
          </v-icon>
          {{ text }}
        </v-card>
        <v-card v-else :to="slug" class="d-flex align-center justify-center pa-4 primary white--text">
          <v-icon class="mr-2" dark>
            {{ icon }}
          </v-icon>
          {{ text }}
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogInformation.show"
      width="750"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 justify-space-between align-center">
          Setting
          <div class="d-flex align-center">
            <v-switch
              v-if="dialogInformation.data"
              v-model="dialogInformation.data.sellnow"
              inset
              label="Activate Sell (Buyback)"
              class="mr-2"
            />
            <v-switch
              v-model="dialogInformation.code"
              inset
              label="CODE"
            />
          </div>
        </v-card-title>
        <v-card-text v-if="dialogInformation.data">
          <v-tabs
            v-model="dialogInformation.tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab>
              About Us
            </v-tab>
            <v-tab>
              FAQ
            </v-tab>
            <v-tab>
              Terms & Conditions
            </v-tab>
            <v-tab>
              Privacy Policy
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="dialogInformation.tab" style="height: 80vh;max-height: 80vh;overflow: auto;">
            <v-tab-item>
              <InformationSettingHtml v-model="dialogInformation.data.about" :code="dialogInformation.code" key-type="about" />
            </v-tab-item>
            <v-tab-item>
              <InformationSettingHtml v-model="dialogInformation.data.faq" :code="dialogInformation.code" key-type="faq" />
            </v-tab-item>
            <v-tab-item>
              <InformationSettingHtml v-model="dialogInformation.data.sdk" :code="dialogInformation.code" key-type="sdk" />
            </v-tab-item>
            <v-tab-item>
              <InformationSettingHtml v-model="dialogInformation.data.kp" :code="dialogInformation.code" key-type="kp" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange"
            text
            @click="dialogInformation.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="UpdateInformation(), dialogInformation.show = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    menus: [
      ['mdi-apps', 'Dashboard', '/', []],
      ['mdi-gold', 'Products', '/inventory', []],
      ['mdi-certificate', 'Certificate', '/certificate', []],
      ['mdi-network-pos', 'Order & Buyback', '/transaction', []],
      ['mdi-account-multiple', 'Customer', '/customer', []],
      ['mdi-map-marker', 'Store / Branch', '/store-manager', []],
      ['mdi-shield-account-outline', 'Account Manager', '/account-manager', []],
      ['mdi-information-outline', 'Information', '', []]
    ],
    dialogInformation: {
      show: false,
      code: false,
      tab: 0,
      data: null
    }
  }),
  methods: {
    OpenInformation () {
      this.$store.dispatch('SETTING_GET')
        .then((res) => {
          if (res && res.data && res.data.data) {
            const d = Object.assign({}, res.data.data)
            if (parseInt(d.sellnow)) {
              d.sellnow = true
            } else {
              d.sellnow = false
            }
            this.dialogInformation.show = true
            this.dialogInformation.code = false
            this.dialogInformation.tab = 0
            this.dialogInformation.data = d
          }
        })
    },
    UpdateInformation () {
      const params = Object.assign({}, this.dialogInformation.data)
      if (params.sellnow) {
        params.sellnow = 1
      } else {
        params.sellnow = 0
      }
      this.dialogInformation.data = params.sellnow
      this.$store.dispatch('SETTING_PROCESS', {
        id: params.id,
        sellnow: params.sellnow ? 1 : 0
      })
    }
  }
}
</script>
