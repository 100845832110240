import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/index'
import './plugins/axios'
import './plugins/components'
// import './registerServiceWorker'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    this.$store.dispatch('INIT')
    this.$store.dispatch('ME')
  },
  mounted () {
    // for (let b = 1; b < 10; b++) {
    //   console.log(this.GenerateSN(b))
    // }
    // console.log(this.StrUTC('2023-03-28 11:50:03'))
  },
  methods: {
    StrUTC (created) {
      const d = (new Date(created)).toISOString()
      const dd = d.split('.')[0]
      const ddd = dd.split('T')
      return ddd[0] + ' ' + ddd[1]
    },
    GenerateSN (id) {
      // MAX = 3599964 -> Z00000
      id = parseInt(id + '')
      const seriQueue = (id / 9999)
      const version = Math.ceil(seriQueue)
      const versionPlain = convertIncrementNumber(version, 2)

      const seriAlphaNumeric = (id % 9999)
      const serial = (seriAlphaNumeric + '').padStart(5, '0')
      return {
        id: id,
        version: version,
        version_alphanumeric: versionPlain,
        serial: serial,
        result: versionPlain + serial
      }

      function convertIncrementNumber (incrementNumber, padLength) {
        const base36String = (incrementNumber - 1).toString(36).toUpperCase()
        const paddedString = base36String.padStart(padLength || 5, '0')
        return paddedString
      }
    }
  }
}).$mount('#app')
