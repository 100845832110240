<template>
  <v-app id="inspire">
    <Toast />
    <Auth v-if="!$store.state.user" />
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-sheet
        color="primary"
        class="pa-4"
        dark
        v-if="$store.state.user"
      >
        <div class="d-flex fill-width align-center justify-space-between">
          <span class="my-4 d-flex align-center text-truncate font-weight-bold" style="max-width:100%;">
            <v-icon color="" large class="mr-2">
              mdi-account-circle
            </v-icon>
            <div class="text-truncate" style="max-width: 100%;">
              <div class="d-flex align-start">
                {{ $store.state.user.name }}
                <v-chip x-small class="text-capitalize ml-2" color="orange">{{ ($store.state.user.role.replace('_', ' ')) }}</v-chip>
              </div>
              <small class="d-block font-weight-regular">{{ $store.state.user.email }}</small>
            </div>
          </span>
        </div>
      </v-sheet>

      <v-divider />

      <v-list v-if="$store.state.user">
        <template v-for="[icon, text, slug] in links.filter(r => $role(r[3], $store.state.user.role))">
          <v-list-item
            :key="icon"
            :to="slug"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider />
        <v-list-item link @click.prevent="DO_LOGOUT()">
          <v-list-item-icon>
            <v-icon color="red">mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="red--text">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="$store.state.user">
      <v-container
        v-if="$role(['director', 'service_advisor', 'head_operation', 'operation_unit', 'logistic'], $store.state.user.role)"
        class="py-2 px-6"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
    <v-btn fab bottom right fixed :color="drawer ? 'red' : 'indigo'" dark class="hidden-sm-and-up" @click.prevent="drawer = !drawer">
      <v-icon>
        {{ drawer ? 'mdi-close' : 'mdi-menu' }}
      </v-icon>
    </v-btn>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    drawer: null,
    links: [
      ['mdi-apps', 'Dashboard', '/', []],
      ['mdi-gold', 'Products', '/inventory', []],
      ['mdi-certificate', 'Certificates', '/certificate', []],
      ['mdi-shield-account-outline', 'Account Manager', '/account-manager', []]
    ]
  }),
  created () {
    this.$store.dispatch('product/PRODUCT_PRICING_GET')
    this.$store.dispatch('product/PRODUCT_PRICING_SET_GET')
  },
  mounted () {
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
.p-relative {
  position: relative!important;
}
.c-pointer {
  cursor: pointer
}
.fill-width {
  width: 100%!important;
}
.file-upload {
  position:fixed;
  display: block;
  bottom: 1px;
  left: calc(100vw - 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.caption-small {
  font-size: .7rem!important ;
}
</style>
