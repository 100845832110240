import request from '../_api/request'

const state = {
}

const mutations = {
}

const actions = {
  async CERTIFICATE_GET (context, query) {
    return request.get('certificate' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_GENERATE (context, params) {
    return request.post('certificate', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_GENERATE_SYNC (context, params) {
    return request.post('certificate/sync', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_PRINTED (context, params) {
    return request.post('certificate/history-printed', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_SIGN (context, params) {
    return request.put('certificate/' + params.certificate, params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_HISTORY_GET (context, q) {
    return request.get('certificate/history' + (q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_HISTORY_UPDATE (context, params) {
    return request.put('certificate/history/' + params.brand + '/' + params.created + '/' + params.status).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CERTIFICATE_HISTORY_DELETE (context, params) {
    return request.delete('certificate/history/' + params.brand + '/' + params.created).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
