import request from '../_api/request'

const state = {
}

const mutations = {
}

const actions = {
  async AREA_GET (context, q) {
    return await request.get('shipping' + (q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
