import request from '../_api/request'

const state = {
  media: [],
  banner: []
}

const mutations = {
  SET_MEDIA (state, v) {
    state.media = v || []
  },
  SET_BANNER (state, v) {
    state.banner = v || []
  }
}

const actions = {
  async VOUCHER_GET (context, q) {
    return await request.get('/voucher' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_PROCESS (context, voucher) {
    return await request.post('/voucher', voucher)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_UPDATE (context, voucher) {
    return await request.put('/voucher/' + voucher.id, voucher)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async VOUCHER_DELETE (context, voucherID) {
    return await request.delete('/voucher/' + voucherID)
      .then(res => res)
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async CUSTOMER_POINT_GET (context, q) {
    return await request.get('/user/customer-point' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async CUSTOMER_POINT_PROCESS (context, params) {
    return await request.post('/user/customer-point', params)
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async TOUR_GUIDE_GET (context, q) {
    return await request.get('/user/tour-guide-selfie' + (q || ''))
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  },
  async TOUR_GUIDE_PROCESS (context, params) {
    return await request.post('/user/tour-guide-selfie', params)
      .then(res => res)
      .catch((err) => {
        return {
          respose: false,
          data: err
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
